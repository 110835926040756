import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  fetchUserInfo,
  updateUserInfo,
  logOutWithFirebase,
} from "../../firebase/auth";
import SubModal from "./submodal";

const Profile = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [subText, setSubText] = useState("");
  const [subText2, setSubText2] = useState("");
  const [changeName, updateName] = useState(false);
  const [setGoal, updateGoal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [heightRadioValue, setHeightRadioValue] = useState("imperial");
  const [weightRadioValue, setWeightRadioValue] = useState("imperial");
  const [isHeightImperial, setIsHeightImperial] = useState(true);
  const [heightValueFt, setHeightValueFt] = useState();
  const [heightValueInch, setHeightValueInch] = useState();
  const [heightValueCm, setHeightValueCm] = useState();
  const [weightValueKg, setWeightValueKg] = useState();
  const [weightValueLbs, setWeightValueLbs] = useState();
  const [spin, setSpin] = useState(false);


  const [genderValue, setGenderValue] = useState();

  // const [heightValue,setHeightValue]=useState();
  const [heightOption, setHeightOption] = useState();

  const [weightValue, setWeightValue] = useState();
  const [weightOption, setWeightOption] = useState();

  const [birthYear, setBirthYear] = useState();

  // const handleChangeHeightOption = (e)=>{
  //   console.log(e.target.value)
  //   if(e.target.value === "0"){ // CM
  //     console.log('In Inches ')
  //     setHeightValue(Number((heightValue*12).toFixed(0)))
  //   }else{
  //     console.log('In FT ')
  //     setHeightValue(Number((heightValue*0.083).toFixed(1)));
  //   }
  // }

  const handleChangeWeightOption = (e) => {
    if (e.target.value === "0") {
      // KG
      // console.log("In KG ");
      setWeightValue(Number((weightValue * 0.453592).toFixed(0)));
    } else {
      // console.log("In LBS ");
      setWeightValue(Number((weightValue * 2.204).toFixed(2)));
    }
  };

  const handleChangeHeightRadio = (event) => {
    setHeightRadioValue(event.target.value);
    if (event.target.value == "imperial") {
      setIsHeightImperial(true);
      let heightResp = convertCmToFeetAndInches(heightValueCm);
      setHeightValueFt(heightResp[0]);
      setHeightValueInch(heightResp[1]);
    } else {
      setIsHeightImperial(false);
      setHeightValueCm(
        convertFeetAndInchesToCm(heightValueFt, heightValueInch)
      );
    }
  };
  const convertFeetAndInchesToCm = (feet, inches) => {
    // console.log("FT ; ", feet * 12 + parseInt(inches));
    let totalInches = feet * 12 + parseInt(inches);
    // console.log("Total Inch : ", totalInches);
    return Number((totalInches * 2.54).toFixed(1));
  };
  const convertCmToFeetAndInches = (input) => {
    let totalInches = input / 2.54;
    let feet = Math.floor(totalInches / 12);
    let inches = Math.round(totalInches % 12);
    return [feet, inches];
  };
  const handleChangeWeightRadio = (event) => {
    setWeightRadioValue(event.target.value);
  };

  const convertInchesToFeetAndInches = (input) => {
    // input = 67 inch , output= 5ft 7 inch
    let feet = Math.floor(input / 12);
    let inches = Math.round(input % 12);
    return [feet, inches];
  };
  const convertFeetAndInchesToInches = (feet, inches) => {
    return Number((feet * 12 + parseInt(inches)).toFixed(1));
  };
  const convertCmToInches = (input) => {
    return Number((input / 2.54).toFixed(1));
  };
  const main = async () => {
    const res = await fetchUserInfo();
    // console.log(res);
    setEmail(res.email);
    setFirstName(res.firstName);
    setLastName(res.lastName);
    setBirthYear(res.birthYear);
    setGenderValue(res.gender);
    setWeightValue(res.weight); // lbs
    setWeightOption("1");
    // setHeightValue(res.height) // INCH
    let heightBreakdown = convertInchesToFeetAndInches(res.height);
    setHeightValueFt(heightBreakdown[0]);
    setHeightValueInch(heightBreakdown[1]);
    setIsHeightImperial(true);

    setHeightOption("0");
    setFullName(`${res.firstName} ${res.lastName}`);
    if (res.subscription) {
      setSubText("Subscription is Active");
      setSubText2("");
    } else {
      if (res.trial) {
        setSubText(`Trial expires at ${res.trialExpiry}.`);
        setSubText2("1 Free Scan");
      } else {
        setSubText(
          "Trial has expired ! Please Subscribe to continue to use our Platform."
        );
        setSubText2("");
      }
    }
    setSpin(false);
  };
  useEffect(() => {
    main();
    updateName(false);
    updateGoal(false);
  }, []);
  const handleClick = async () => {
    // alert("abc")
    setSpin(true)
    // let isHeight =
    let heightValue = 0;
    if (isHeightImperial) {
      heightValue = convertFeetAndInchesToInches(
        heightValueFt,
        heightValueInch
      );
    } else {
      heightValue = convertCmToInches(heightValueCm);
    }

    if (
      weightValue === null ||
      heightValue === null ||
      genderValue === null ||
      weightValue === 0 ||
      heightValue === 0 
    ) {
      updateGoal(false);
    }

    // let heightInInches;
    let weightInLbs;
    if (weightOption === "0") {
      weightInLbs = Number((weightValue * 2.20462).toFixed(0));
    } else {
      console.log('Weight Value : ',typeof weightValue)
      weightInLbs = Number(Number(weightValue).toFixed(2));
    }
    setFullName(`${firstName} ${lastName}`);
    const res = await updateUserInfo(
      setGoal,
      heightValue,
      weightInLbs,
      Number(genderValue)
    );
    // console.log(res);
    if (true) {
      main();
    }
  };
  const handleLogOut = async () => {
    // do something here
    logOutWithFirebase();
    sessionStorage.clear();
    // navigate("/");
    window.location.href = "/login";
  };
  return (
    <div
      className=" flex min-h-screen flex-col bg-navColor"
      // style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      {/* <SubModal /> */}

      {/* <div className=" mx-auto">
        <img src={Logo} className="md:h-36 h-24 mx-auto" />
      </div> */}
      <div className="flex flex-col md:flex-row w-full mt-10">
        {/* Upper div */}
        <div className="flex md:w-1/2 w-full">
          {/* Details */}
          <div className="mx-auto md:w-1/2 w-full">
            {error ? (
              <>
                <div
                  className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50  "
                  role="alert"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 inline w-5 h-5 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div>{error}!</div>
                </div>
              </>
            ) : null}

            {/* Goal: {setGoal.toString()} name: {changeName.toString()}
        password: {setPassword.toString()} */}
            <form >
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-white font-bold bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={firstName}
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-white  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  First Name
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="floating_password"
                  id="floating_password"
                  className="block py-2.5 px-0 w-full text-sm text-white font-bold bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={lastName}
                />
                <label
                  htmlFor="floating_password"
                  className="peer-focus:font-medium absolute text-sm text-white  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Last Name
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  name="repeat_password"
                  id="floating_repeat_password"
                  className="block py-2.5 px-0 w-full text-sm text-white font-bold bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={email}
                />
                <label
                  htmlFor="floating_repeat_password"
                  className="peer-focus:font-medium absolute text-sm text-white  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group flex flex-row  justify-between">
                <div></div>
              </div>
            </form>
          </div>
        </div>
        <div>
          {/* Other details */}

          <div className="flex flex-col text-white">
            <div className="mb-2 ">
              <label
                htmlFor="large-input"
                className="block mb-2 text-sm font-medium text-white  "
              >
                Height
              </label>
              <div className="flex flex-row md:space-x-4 mb-2 ">
                <div>
                  <input
                    type="radio"
                    id="imperial"
                    name="measurement"
                    value="imperial"
                    className="mr-2"
                    checked={heightRadioValue === "imperial"}
                    onChange={handleChangeHeightRadio}
                  />
                  <label htmlFor="imperial ml-3">Imperial</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="metric"
                    name="measurement"
                    value="metric"
                    className="mr-2"
                    checked={heightRadioValue === "metric"}
                    onChange={handleChangeHeightRadio}
                  />
                  <label htmlFor="metric">Metric</label>
                </div>
              </div>
              {isHeightImperial ? (
                <div className="flex flex-row space-x-0 w-3/4 text-white">
                  <div className="flex flex-row space-x-2 ">
                    {/* <label htmlFor="ft-input">Ft</label> */}
                    <input
                      id="ft-input"
                      type="number"
                      placeholder="Ft"
                      value={heightValueFt}
                      onChange={(e) => {
                        setHeightValueFt(e.target.value);
                        updateGoal(true);
                      }}
                      className="block w-2/3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                    />
                    <div className="my-auto">
                      <label htmlFor="inch-input">Ft</label>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-row space-x-2 ">
                      <input
                        id="inch-input"
                        type="number"
                        placeholder="Inch"
                        value={heightValueInch}
                        onChange={(e) => {
                          setHeightValueInch(e.target.value);
                          updateGoal(true);
                        }}
                        className="block w-2/3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                      />
                      <div className="my-auto ml-0">
                        <label htmlFor="inch-input">Inch</label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row space-x-2">
                    
                    <input
                      type="number"
                      placeholder="Cm"
                      value={heightValueCm}
                      onChange={(e) => {
                        setHeightValueCm(e.target.value);
                        updateGoal(true);
                      }}
                      id="cm-input"
                      className="block w-1/4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                    />
                    <div className="my-auto ml-0">
                     <label htmlFor="cm-input">Cm</label>
                    </div>

                  <div>
                    {/* <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 ">Select an option</label> */}
                    {/* <select value={heightOption} onChange={(e)=>{setHeightOption(e.target.value);handleChangeHeightOption(e);}} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                        <option value={0}>Ft</option>
                        <option value={1}>Inch</option>
                      </select> */}
                  </div>
                </div>
              )}
            </div>
            <div className="mb-1">
              <label
                htmlFor="large-input"
                className="block mb-2 text-sm font-medium text-white "
              >
                Weight
              </label>
              <div className="flex flex-row space-x-1">
                <div>
                  <input
                    type="number"
                    value={weightValue}
                    onChange={(e) => {
                      setWeightValue(e.target.value);
                      updateGoal(true);
                    }}
                    id="large-input"
                    className="block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                  />
                </div>
                <div>
                  {/* <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 ">Select an option</label> */}
                  <select
                    value={weightOption}
                    onChange={(e) => {
                      setWeightOption(e.target.value);
                      handleChangeWeightOption(e);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    <option value={0}>KG</option>
                    <option value={1}>Lbs</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-1">
              <label
                htmlFor="large-input"
                className="block mb-2 text-sm font-medium text-white "
              >
                Physical Gender
              </label>
              <div className="flex flex-row space-x-1">
                <div>
                  {/* <input type="text" id="large-input" className="block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 " /> */}
                </div>
                <div>
                  {/* <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 ">Select an option</label> */}
                  <select
                    value={genderValue}
                    onChange={(e) => {
                      setGenderValue(e.target.value);
                      updateGoal(true);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    <option value={0}>M</option>
                    <option value={1}>F</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-1">
              <label
                htmlFor="large-input"
                className="block mb-2 text-sm font-medium text-white "
              >
                Year of birth
              </label>
              <div className="flex flex-row space-x-1">
                <div>
                  <input
                    type="number"
                    value={birthYear}
                    onChange={(e) => {
                      setBirthYear(e.target.value);
                      updateGoal(true);
                    }}
                    className="block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                  />
                </div>
                <div>
                  {/* <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 ">Select an option</label> */}
                  {/* <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                    <option selected>M</option>
                    <option value="US">F</option>
                    
                    </select> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="mx-auto font-bold text-white py-1 text-lg">{subText}</div>
      <div className="mx-auto font-bold text-white py-1 text-lg">
        {subText2}
      </div>
      <div className="error__value mx-auto text-center py-1 text-red-500">
        {spin ? (
          <>
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-gray-200 animate-spin  fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </>
        ):null }{" "}
      </div>
      <div className="mx-auto">
        <button
          type="submit"
          onClick={() => handleClick()}
          className="text-white md:px-20 px-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center "
        >
          Update
        </button>
      </div>
      
    </div>
  );
};

export default Profile;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {
  getAuth
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8S9fsr0XMcxMj4iuiyWnWNhP_dX_rDvM",
  authDomain: "p2-pch-project.firebaseapp.com",
  projectId: "p2-pch-project",
  storageBucket: "p2-pch-project.appspot.com",
  messagingSenderId: "1099498149258",
  appId: "1:1099498149258:web:b2e1d49d2bcf9e24576473",
  measurementId: "G-1P4S025N95"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import HowToImproveButton from "../../common/HowToImproveButton";
import BackToMenu from "../../common/BackToMenu";
import MyChart2 from "../dashboard/mychart2";
import HealthnessCard from "../../common/card";
import StressIco from '../../../assets/wellness/ICON_STRESS-LEVEL.png'
import FatigueIco from '../../../assets/wellness/ICON_FATIGUE_LEVEL.png'
import YyIco from '../../../assets/wellness/ICON_ORGANS_ENERGY.png'
import HBcoh from '../../../assets/wellness/ICON_MY_HEART_BRAIN_COHERENCE.png'
import BackToPreviousPage from '../../common/BackToPreviousPage';
import Tooltip from '../../common/Tooltip';

function MyEnergy() {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
  return (
    <div
      className=" w-full flex flex-col md:flex-row pl-3" style={{backgroundColor:"#bcd7d3"}}
    >
      <div className="grow mt-5">
        <div className="dash1">
            <div>
                <Tooltip
                text="With the stress level feature, you can easily determine their current stress level by monitoring their heart-rate variability. Physical symptoms of stress may include aches and pains, chest pain or a feeling of a racing heart, exhaustion or difficulty sleeping, headaches, dizziness or shaking, high blood pressure, muscle tension or jaw clenching, stomach or digestive issues, and trouble with sexual activity. Stress can also lead to emotional and mental symptoms such as anxiety or irritability, depression, panic attacks, and sadness."
                />
            </div>
            <div className="pl-5 pr-8">
                <Link to={"/myenergy/stress"} className="">
                <HealthnessCard
                    feature={12}
                    reconID="STRESS"
                    icon={StressIco}
                    vital="STRESS LEVEL"
                    textColor="#afedf2"
                />
                </Link>
            </div>
            <div className="dash2">
                <div className="dash4">
                <MyChart2 feature={12} ChartLabel="STRESS LEVEL" />
                </div>
                <div className='dash3'>

                <HowToImproveButton pagelink="/myenergy/stress"/>
                </div>
            </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
            <div>
                <Tooltip
                text="Fatigue is a persistent sensation of tiredness or weakness that can be physical, mental, or both. It can affect anyone and is a common experience for most adults. Fatigue can lead to various physical, mental, and emotional symptoms, such as chronic tiredness or sleepiness, headaches, dizziness, sore or aching muscles, muscle weakness, slowed reflexes and responses, impaired decision-making and judgement, and moodiness, including irritability."
                />
            </div>
            <div className="pl-5 pr-8">
                <Link to={"/myenergy/fatigue"} className="">
                <HealthnessCard
                    feature={13}
                    reconID="FATIGUE"
                    icon={FatigueIco}
                    vital="FATIGUE LEVEL"
                    textColor="#87bdc2"
                />
                </Link>
            </div>
            <div className="dash2">
                <div className="dash4">
                <MyChart2 feature={13} ChartLabel="FATIGUE LEVEL" />
                </div>
                <div className='dash3'>

                <HowToImproveButton pagelink="/myenergy/fatigue"/>
                </div>
            </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
            <div>
                <Tooltip
                text="Traditional Chinese Medicine (TCM) relies on the concept of Yin and Yang to comprehend health and to diagnose and treat illnesses. Each organ in the body is associated with specific elements, and its functioning depends on the balance of these elements and the overall inner balance. When organs become imbalanced, individuals may experience physical and emotional symptoms, including coughing and other illnesses."
                />
            </div>
            <div className="pl-5 pr-8">
                <Link to={"/myenergy/organsenergy"} className="">
                <HealthnessCard
                    feature={21}
                    reconID="YING_YANG"
                    icon={YyIco}
                    vital="ORGANS ENERGY"
                    textColor="#4da3ab"
                />
                </Link>
            </div>
            <div className="dash2">
                <div className="dash4">
                <MyChart2 feature={21} ChartLabel="ORGANS ENERGY" />
                </div>
                <div className='dash3'>

                <HowToImproveButton pagelink="/myenergy/organsenergy"/>
                </div>
            </div>
        </div>
        {/* <div className="border-b border-blue-900 my-4"></div> */}
        {/* <div className='relative'>
            <div className="dash1">
                <div>
                    <Tooltip
                    text="Coherence refers to the state of harmony between your mind, body, and spirit. This unity allows you to be more present and aware, ultimately leading to improved creativity, decision-making, clarity of thought, emotional stability, physical energy, stress reduction, and better sleep quality. Achieving coherence means that your brain works in sync with your heart and body, resulting in optimal healing and overall functioning."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/myenergy/heartbraincoherence"} className="">
                    <HealthnessCard
                        feature={22}
                        reconID="SENSITIVITY"
                        icon={HBcoh}
                        vital="HEART & BRAIN COHERENCE"
                        textColor="#69d3dc"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart2 feature={22} ChartLabel="HEART & BRAIN COHERENCE" />
                    </div>
                    <div className='dash3'>

                    </div>
                    <HowToImproveButton pagelink="/myenergy/heartbraincoherence"/>

                </div>
            </div>
        </div> */}
        
        <BackToMenu pagelink="/mywellness" menuPage="Back to My Wellness"/>
        <BackToPreviousPage pagelink='/dashboard' menuPage='Back to My Total Report'/>
      </div>
    </div>
  )
}

export default MyEnergy
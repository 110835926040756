import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { subStateListner } from "../../firebase/auth";
// import { fetchSubscriptionInfo,updateFirebaseSubscriptionStatusUsingPayPal } from "../../firebase/auth";
export default function SubModal() {
  let [showModal, setShowModal] = React.useState(true);
  const navigate = useNavigate();
  const requireSubscription = async()=>{
    let subValue = await subStateListner()
    setShowModal(subValue)
  }
  const location = useLocation();
  useEffect(()=>{
    console.log('Location : ',location.pathname)
    requireSubscription();
  },[location])
  
  return (
    <>
      {showModal ? null : (
        <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}

              {/*body*/}
              <div className="mt-2">
                <p className="text-sm p-4 font-serif font-black">
                You have been logged out of the session !
                </p>
                <p className="pl-4  mt-2">Thank you!</p>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <div className="mt-4">
                  <a
                    type="button"
                    href="https://my.playcarehealth.com/"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  >
                    Go back to Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
      ) }
    </>
  );
}

import { Link, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/main/dashboard/dashboard";
import React, { useEffect, useState } from "react";
import HeartRate from "./components/main/healthness/heartrate";
import Scan from "./components/main/scan";
import Cleansers from "./components/main/skin_health/cleansers";
import Moisturizers from "./components/main/skin_health/moisturizers";
import BreathingRate from "./components/main/healthness/breathingrate";
import Spo2 from "./components/main/healthness/spo2";
import BloodPressure from "./components/main/healthness/bloodpressure";
import Cholesterol from "./components/main/healthness/cholesterol";
import BloodSugar from "./components/main/healthness/bloodsugar";
import Sunscreen from "./components/main/skin_health/sunscreen";
import Exfoliants from "./components/main/skin_health/exfoliants";
import AntiAging from "./components/main/skin_health/Antiaging";
import Brightening from "./components/main/skin_health/Brightening";
import AcneFighting from "./components/main/skin_health/Acnefighting";
import SoothingSensitiveSkin from "./components/main/skin_health/soothingsensitiveskin";
import VitaminD from "./components/main/wellness/vitamind";
import VitaminC from "./components/main/wellness/vitaminc";
import VitaminE from "./components/main/wellness/vitamine";
import VitaminB3 from "./components/main/wellness/vitamineb3";
import VitaminB9 from "./components/main/wellness/vitamineb9";
import VitaminBComplex from "./components/main/wellness/vitaminbcomp";
import MineralMg from "./components/main/wellness/MineralMg";
import MineralCa from "./components/main/wellness/MineralCa";
import MineralFe from "./components/main/wellness/MineralFe";
import MineralPh from "./components/main/wellness/MineralPh";
import NutriProbio from "./components/main/wellness/NutriProbio";
import NutriLTh from "./components/main/wellness/NutriLTh";
import NutriPsa from "./components/main/wellness/NutriPsa";
import NutriOm3 from "./components/main/wellness/NutriOm3";
import FitStress from "./components/main/wellness/FitStress";
import FitFatigue from "./components/main/wellness/FitFatigue";
import FitBone from "./components/main/wellness/FitBone";
import FitImmune from "./components/main/wellness/FitImmune";
import GlucoseMeta from "./components/main/wellness/GlucoseMeta";
import CholMeta from "./components/main/wellness/CholMeta";
import O2Meta from "./components/main/wellness/O2Meta";
import YinYang from "./components/main/wellness/YinYang";
import Sensitivity from "./components/main/wellness/Sensitivity";
import Circulation from "./components/main/wellness/Circulation";
import MyVitalDash from "./components/main/healthness/MyVitalDash";
import Profile from "./components/main/profile/profile";
import {
  scanRecordsListner,
  subStateListner,
} from "./components/firebase/auth";
import SubModal from "./components/main/profile/submodal";
import Disclaimer from "./components/main/disclaimer";
import { ModalCtxProvider } from "./components/store/modalcontext";
import { ModalCtxProvider2 } from "./components/store/modalcontext2";
import HowToUseModal from "./components/common/howtomodal";
import Faq from "./components/main/faq/faq";
import LandingPage from './components/main/LandingPage';
// import LandingPage from "./components/main/TestLandingPage";
import RedirectScanModal from "./components/common/redirectScanModal";
import PrivacyPolicy from "./components/common/privacypolicy";
import HowToScanModal from "./components/common/howtoScanModal";
import Layout from "./components/layouts/layouts";
// import Refer from "./components/main/Refer";
import MyHealthDash from "./components/main/healthness/MyHealthDash";
import MyMajorHealth from "./components/main/healthness/MyMajorHealth";
import MyNutrients from "./components/main/healthness/MyNutrients";
import MyWellness from "./components/main/wellness/MyWellness";
import MyEnergy from "./components/main/wellness/MyEnergy";
import MySkinHealth from "./components/main/skin_health/MySkinHealth";
import Demo from "./components/main/demo";

function RequireAuth({children}){
  if(localStorage.getItem("status") === "true"){
    return children
  }else{
    window.location.href="https://my.playcarehealth.com/"
  }
}

function App() {
  const [navbar, setNavBar] = useState(false);
  const [class1, setClass1] = useState("");
  const [class2, setClass2] = useState("");
  let authurls=['/','/profile','/demo']
  let scanruls = ["/scan"];
  const location = window.location.pathname;
  let [redirectToScan, setRedirectToScan] = useState(true);
  let [inScanPage, setInScanPage] = useState(true);
  
  const RequireScanRecords = async () => {
    let recordStatus = await scanRecordsListner();
    setRedirectToScan(recordStatus); // false means show popup , true means not show popup
  };

  useEffect(() => {
    RequireScanRecords();
  });

  let checkoccurance = authurls.includes(location);
  let scanoccurance = scanruls.includes(location);

  useEffect(() => {
    setUp()
    setUpscan();
  }, [location]);

  const setUpscan = () => {
    if (scanoccurance) {
      setInScanPage(true);
    } else {
      setInScanPage(false);
    }
  };

  const setUp = () => {
    if (checkoccurance) {
      setNavBar(false);
      // setClass2("");
      // setClass1("");
    } else {
      setNavBar(true);
      // setClass2("p-4    rounded-lg  mt-14");
      // setClass1("p-4 sm:ml-64");
    }
  };

  return (
    // <ModalCtxProvider
    <ModalCtxProvider>
      <ModalCtxProvider2>
        <Router>
          <Layout>
            <HowToUseModal />
            <HowToScanModal />
            {navbar ? (
              <>
                
                {redirectToScan || inScanPage  ? null : (
                  <>
                  {/* <RequireAuth> */}
                  <RedirectScanModal />
                  {/* </RequireAuth> */}
                  </>
                )}
                <SubModal/>
              </>
            ) : null}
            <div className={class1}>
              <div className={class2}>
                <Routes>
                  <Route
                    path="/myhealth"
                    element={
                      <RequireAuth>
                        <MyHealthDash />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route path="/faq" element={<Faq />}>
                    {" "}
                  </Route>
                  <Route
                    path="/dashboard"
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route path="/" element={<LandingPage/>}>
                    {" "}
                  </Route>
                  <Route
                    path="demo"
                    element={
                      <Demo />
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/cleanliness"
                    element={
                      <RequireAuth>
                        <Cleansers />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/hydration"
                    element={
                      <RequireAuth>
                        <Moisturizers />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="scan"
                    element={
                      <RequireAuth>
                        <Scan />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/protection"
                    element={
                      <RequireAuth>
                        <Sunscreen />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/sensitivity"
                    element={
                      <RequireAuth>
                        <SoothingSensitiveSkin />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/exfoliation"
                    element={
                      <RequireAuth>
                        <Exfoliants />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="privacy-policy"
                    element={
                      <RequireAuth>
                        <PrivacyPolicy />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients"
                    element={
                      <RequireAuth>
                        <MyNutrients />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/vitamind"
                    element={
                      <RequireAuth>
                        <VitaminD />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/vitaminc"
                    element={
                      <RequireAuth>
                        <VitaminC />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/vitamine"
                    element={
                      <RequireAuth>
                        <VitaminE />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/vitaminb3"
                    element={
                      <RequireAuth>
                        <VitaminB3 />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/vitaminb12"
                    element={
                      <RequireAuth>
                        <VitaminBComplex />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  {/* <Route
                    path="mynutrients/vitaminb9"
                    element={
                      <RequireAuth>
                        <VitaminB9 />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route> */}
                  <Route
                    path="mynutrients/magnesium"
                    element={
                      <RequireAuth>
                        <MineralMg />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/calcium"
                    element={
                      <RequireAuth>
                        <MineralCa />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  {/* <Route
                    path="mynutrients/iron"
                    element={
                      <RequireAuth>
                        <MineralFe />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route> */}
                  <Route
                    path="mynutrients/phosphorous"
                    element={
                      <RequireAuth>
                        <MineralPh />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/probiotics"
                    element={
                      <RequireAuth>
                        <NutriProbio />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/ltheanine"
                    element={
                      <RequireAuth>
                        <NutriLTh />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/policosanol"
                    element={
                      <RequireAuth>
                        <NutriPsa />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mynutrients/omega3"
                    element={
                      <RequireAuth>
                        <NutriOm3 />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="profile"
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mywellness"
                    element={
                      <RequireAuth>
                        <MyWellness />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myenergy"
                    element={
                      <RequireAuth>
                        <MyEnergy />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myenergy/stress"
                    element={
                      <RequireAuth>
                        <FitStress />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myenergy/fatigue"
                    element={
                      <RequireAuth>
                        <FitFatigue />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mymajorhealth/bonehealth"
                    element={
                      <RequireAuth>
                        <FitBone />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mymajorhealth/immunity"
                    element={
                      <RequireAuth>
                        <FitImmune />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mymajorhealth"
                    element={
                      <RequireAuth>
                        <MyMajorHealth />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  {/* <Route
                    path="mymajorhealth/glucosemetabolism"
                    element={
                      <RequireAuth>
                        <GlucoseMeta />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mymajorhealth/cholesterolmetabolism"
                    element={
                      <RequireAuth>
                        <CholMeta />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mymajorhealth/o2metabolism"
                    element={
                      <RequireAuth>
                        <O2Meta />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route> */}
                  <Route
                    path="myenergy/organsenergy"
                    element={
                      <RequireAuth>
                        <YinYang />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  {/* <Route
                    path="myenergy/heartbraincoherence"
                    element={
                      <RequireAuth>
                        <Sensitivity />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route> */}
                  <Route
                    path="mymajorhealth/circulation"
                    element={
                      <RequireAuth>
                        <Circulation />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth"
                    element={
                      <RequireAuth>
                        <MySkinHealth />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/aging"
                    element={
                      <RequireAuth>
                        <AntiAging />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/brightness"
                    element={
                      <RequireAuth>
                        <Brightening />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myskinhealth/acne"
                    element={
                      <RequireAuth>
                        <AcneFighting />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myvitals"
                    element={
                      <RequireAuth>
                        <MyVitalDash />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myvitals/spo2"
                    element={
                      <RequireAuth>
                        <Spo2 />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  {/* <Route
                    path="myvitals/bloodpressure"
                    element={
                      <RequireAuth>
                        <BloodPressure />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="mymajorhealth/cholesterol"
                    element={
                      <RequireAuth>
                        <Cholesterol />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myvitals/bloodsugar"
                    element={
                      <RequireAuth>
                        <BloodSugar />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route> */}
                  <Route
                    path="myvitals/heart-rate"
                    element={
                      <RequireAuth>
                        <HeartRate />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  <Route
                    path="myvitals/breathing-rate"
                    element={
                      <RequireAuth>
                        <BreathingRate />
                      </RequireAuth>
                    }
                  >
                    {" "}
                  </Route>
                  {/* <Route path="/healthness" element = {<Healthness />}> </Route>
            <Route path="/wellness" element = {<Wellness />}> </Route>
            <Route path="/skin_health" element = {<SkinHealth />}> </Route> */}
                </Routes>
                <Disclaimer />
              </div>
            </div>
          </Layout>
        </Router>
      </ModalCtxProvider2>
    </ModalCtxProvider>
  );
}

export default App;

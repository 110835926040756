import Layout from "./layout";
import { React, useEffect, useRef, useState } from "react";
import inst_video_1 from "../../assets/video/inst_video.webm";
import inst_video_2 from "../../assets/video/inst_video.mp4";
// import inst_video_2 from "../video/inst_video.mp4";
import "../../assets/css/scan.css";
import { useNavigate } from "react-router-dom";
import { updateVerifyScanRecord } from "../firebase/scans";
import Vitals from "./vitals";
// import LeftSection from "../common/LeftSection";
import Tooltip from "../common/Tooltip";
// import Faq from "./faq/faq";
import { Link } from "react-router-dom";

const Demo = () => {
  const [hideVideo, sethideVideo] = useState(false);
  const [hideSkip, sethideSkip] = useState(false);
  const vidRef = useRef(null);
  const scanVidRef = useRef(null);
  const [fpsStarted, setStartedFps] = useState(false);
  const [hideReportBtn, setHideReportBtn] = useState(true);
  const [showSubNow,setShowSubNow]=useState(false);
  const iOS =
    !!window.navigator.platform &&
    /iPad|iPhone|iPod/.test(window.navigator.platform);
  const navigate = useNavigate();
  const handleStopVideo = () => {
    vidRef.current.pause();
  };
  const handleStartedFps = () => {
    setStartedFps(true);
  };
  const handleReportBtnState = () => {
    setHideReportBtn(false);
  };
  const [spin, setSpin] = useState(false);
  
  useEffect(()=>{
    localStorage.setItem("status","true");
  })
  useEffect(() => {
    const canvas = document.getElementById("canvas");
    if (canvas) {
      const pageLoadedEvent = new Event("page_loaded");
      document.dispatchEvent(pageLoadedEvent);
    }
  }, []);
  useEffect(() => {
    if (scanVidRef.current) {
      scanVidRef.current.playsInline = true;
      scanVidRef.current.muted = true;
    }
  }, [scanVidRef]);
  const handleVerifyResultClick = () => {
      window.location.href = "https://playcarehealth.com/demo-return"; // link to pch backoffice
  };
  return (
    <div className="flex w-full flex-row">
      {/* <SubModal />
      <Nav /> */}
      {/* <Faq /> */}
      <div className="mx-auto version mt-5">
        {/* <div className="flex flex-row justify-between mt-3 w-full mb-2">
          <div className="md:w-1/2 w-3/4 flex flex-row ">
            <div
              className="text-blue-500 md:text-2xl font-black grow"
              style={{ whiteSpace: "nowrap" }}
            >
              <p id="checker" className="">Modules Loading</p>
            </div>
            <div>
              <Tooltip
                text=' The scanning modules will take some time to load in the browser.
              Please wait until "Modules Ready" is shown.'
              />
            </div>
          </div>
          <div className="group flex relative w-full"></div>
          <div></div>
        </div> */}
        <div className="flex flex-row w-full mb-3">
          <div className="flex grow  flex-col mb-2">
            <p id="checker" className=" text-purple-500 md:text-2xl font-avenirNex font-black">Preparing for scanning ...</p>
            <p className="version" hidden>version-3.0.221205</p>
            <p className="fps" hidden>
              FPS : <span id="fps_msg"></span>
            </p>
          </div>
          <div className="flex justify-end my-auto">
            {/* <div className="group flex relative w-full text-end justify-end  align-text-bottom my-auto">
              <span className=" text-white">
                <i
                  className="fa fa-info-circle text-blue-800 w-10"
                  aria-hidden="true"
                ></i>
              </span>
              <div
                className="group-hover:opacity-100 transition-opacity bg-blue-400 px-1 w-full  text-gray-100 rounded-md absolute left-1/2 
              -translate-x-1/2 translate-y-1/2 opacity-0  mx-auto"
              >
                The scanning modules will take some time to load in the browser.
                Please wait until "Modules Ready" is shown.
              </div>
            </div> */}
            {/* <Tooltip
              text='This area will show the tutorial video while loading the modules. You can click "Skip video" to enter the scanning mode. By clicking the "Start scan" button at the lower-left, you can start a scan. Keep your facial region visible with the green frame, which means good facial imaging quality.
'
            /> */}
          </div>
        </div>
        <div className="webcam__container">
          <div className="skip__container">
            <div id="skip__indication" hidden onClick={handleStartedFps}></div>
            {fpsStarted ? (
              <div>
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    SKIP VIDEO
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="bg-[#67ac5b] rounded-full text-white py-3 px-6 text-sm text-center font-semibold no-underline border-none inline-block my-1 mx-0.5 cursor-pointer shadow-lg  transition duration-200 transform hover:scale-110"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    SKIP VIDEO
                  </button>
                )}
              </div>
            ) : (
              <div hidden className="">
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    SKIP VIDEO
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="bg-[#67ac5b] rounded-full text-white py-3 px-6 text-sm text-center font-semibold no-underline border-none inline-block my-1 mx-0.5 cursor-pointer shadow-lg  transition duration-200 transform hover:scale-110"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    SKIP VIDEO
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="inst__container border">
            <video
              ref={vidRef}
              autoPlay
              loop
              muted
              playsInline
              controls
              hidden={hideVideo}
              width="640px"
              height="480px"
              className="inst__video bg-white w-[640px] h-[480px]"
            >
              {iOS ? (
                <source src={inst_video_2} type="video/mp4"></source>
              ) : (
                <source src={inst_video_1} type="video/webm"></source>
              )}
            </video>
          </div>
          <div className="webcam__video">
            <div class="h-auto z-10 m-5 absolute  w-80 md:w-[600px]">
              <div id="progress__value" class="h-8 bg-[#05fa05] bg-opacity-50 text-center leading-8 text-white rounded-full z-negative" style={{width:"1%"}}></div>
            </div>
            {/* <div className="progress__container w-80 md:w-[600px]">
              <div id="progress__value"></div>
            </div> */}
            <video
            id="video"
            width="640px"
            height="480px"
            ref={scanVidRef}
            autoPlay
            playsInline
            muted
            className="w-[640px] h-[480px]"
            ></video>
            <canvas
              id="canvas"
              className="aspect-video w-[640px] h-[480px] hidden "
            ></canvas>
          </div>
          {/* <div className="instruction__box">
            <p className="">
              Please look directly into the camera during scanning
            </p>
          </div> */}
          <div className="button__container">
            <button id="start_stop_btn" value="1" className="rounded-full border-none text-white py-3 px-4 text-sm text-center no-underline inline-block my-1 mx-0.5 cursor-pointer bg-[#67ac5b] font-semibold  shadow-lg  transition duration-200 transform hover:scale-110">
              START CAPTURE
            </button>
          </div>
        </div>

        {/* <div className="flex flex-col w-full">
          <div className="info__container my-14 md:my-0">
            <p className="info__message">
              Message : <span className="info__value" id="prog_dynamic"></span>
            </p>
          </div>
        </div> */}
        <div>
          <div
            id="report__indication"
            hidden
            onClick={handleReportBtnState}
          ></div>
          {hideReportBtn ? null : (
            // <center>
            <div>
              <div className="flex mt-4 flex-row mx-auto w-full justify-center px-auto text-center object-center content-center">
                <div className="flex">
                  <button
                    className="z-30 bg-[#67ac5b] shadow-lg text-sm transition duration-200 transform hover:scale-110 text-white font-semibold py-3 px-6 my-1 mx-0.5 rounded"
                    onClick={handleVerifyResultClick}
                    hidden={hideReportBtn}
                  >
                    CHECK MY REPORT
                  </button>
                  {/* <Tooltip
                    text='After the scanning, you can click "Start scan" to retry
                    scanning if there is any issue. Only the last scanning will be
                    used. You can check the full report by clicking the "Full
                    report" button.'
                  /> */}
                </div>
              </div>
              <div className="error__value mx-auto text-center py-1 text-red-500">
                {spin ? (
                  <>
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin  fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </>
                ) : null}{" "}
              </div>
            </div>
            // </center>
          )}
        </div>
        <Vitals  />
        
      </div>
      {/* <div className="flex">
        <LeftSection />
      </div> */}
    </div>
  );
};

export default Demo;

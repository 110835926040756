import React, { useEffect } from "react";
import MyChart2 from "../dashboard/mychart2";
import HealthnessCard from "../../common/card";
import HeartIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_HEART_RATE.png";
import RespIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_RESPERATORY_RATE.png"
import Spo2Icon from "../../../assets/Healthiness/myvital/ICONS/ICON_OXYGEN LEVEL.png"
import BPIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_BLOOD_PRESSURE.png"
import BSIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_BLOOD_SUGAR.png"
import { Link } from "react-router-dom";
import HowToImproveButton from "../../common/HowToImproveButton";
import BackToMenu from "../../common/BackToMenu";
import BackToPreviousPage from "../../common/BackToPreviousPage";
import Tooltip from "../../common/Tooltip";

const MyVitalDash = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div
      className=" w-full flex flex-col md:flex-row pl-3 bg-sky-200"
    >
      <div className="grow mt-5">
        <div className="dash1">
          <div>
              <Tooltip
              text="Your heart rate, or pulse, is the number of times your heart beats per minute. When the heart beats too fast, it may not pump enough blood to the rest of the body. As a result, the organs and tissues may not get enough oxygen. In general, tachycardia may lead to the following signs and symptoms: a sensation of a racing, pounding heartbeat or flopping in the chest (palpitations), chest pain, fainting (syncope), lightheadedness, rapid pulse rate, and shortness of breath. Heart arrhythmias may not cause any signs or symptoms. A doctor may notice an irregular heartbeat when examining you for another health reason. In general, signs and symptoms of arrhythmias may include: a fluttering in the chest, a racing heartbeat (tachycardia), a slow heartbeat (bradycardia), chest pain, shortness of breath, anxiety, fatigue, lightheadedness or dizziness, sweating, fainting (syncope) or near fainting"
              />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/myvitals/heart-rate"} className="">
              <HealthnessCard
                feature={2}
                reconID="HR"
                icon={HeartIcon}
                vital="HEART RATE"
                textColor="#377ac7"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart2 feature={2} ChartLabel="HEART RATE" />
            </div>
            <div className="dash3">

              <HowToImproveButton pagelink="/myvitals/heart-rate"/>
            </div>
          </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
          <div>
              <Tooltip
              text="The breathing rate is the number of breaths a person takes per minute. A high or low breathing rate may be a sign that an underlying issue is present. Common causes of an increased breathing rate include anxiety, fever, respiratory diseases, heart diseases, and dehydration. Common causes of a low breathing rate include drug overdoses, obstructive sleep apnea, and head injuries."
              />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/myvitals/breathing-rate"} className="">
              <HealthnessCard
                feature={4}
                reconID="RR"
                icon={RespIcon}
                vital="BREATHING RATE"
                textColor="#5f95d7"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart2 feature={4} ChartLabel="BREATHING RATE" />
            </div>
            <div className="dash3">

              <HowToImproveButton pagelink="/myvitals/breathing-rate"/>
            </div>
          </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
          <div>
              <Tooltip
              text="Blood oxygen level is the amount of oxygen circulating in the blood. Many heart and lung conditions put you at risk for hypoxemia. High Oxygen Levels can include the next symptoms: pleuritic chest pain, substernal heaviness, coughing, and dyspnea secondary to tracheobronchitis and absorptive atelectasis, which can lead to pulmonary edema. Low OXYGEN LEVEL causes symptoms like headache, difficulty breathing, rapid heart rate, and bluish skin. It can also happen at high altitudes. "
              />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/myvitals/spo2"} className="">
              <HealthnessCard
                feature={3}
                reconID="SPO2"
                icon={Spo2Icon}
                vital="OXYGEN LEVEL"
                textColor="#96bae4"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart2 feature={3} ChartLabel="OXYGEN LEVEL" />
            </div>
            <div className="dash3">

              <HowToImproveButton pagelink="/myvitals/spo2"/>
            </div>
          </div>
        </div>
        {/* <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
          <div>
              <Tooltip
              text="Blood pressure is the pressure of blood pushing against the walls of your arteries. Most people with high blood pressure have no symptoms, even if blood pressure readings reach dangerously high. You can have high blood pressure for years without any symptoms. But may you can have headaches, shortness of breath, and nosebleeds. Low blood pressure (hypotension) symptoms may include: blurred or fading vision, dizziness or lightheadedness, fainting, fatigue, trouble concentrating, and nausea. "
              />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/myvitals/bloodpressure"} className="">
              <HealthnessCard
                feature={6}
                reconID="BP"
                icon={BPIcon}
                vital="BLOOD PRESSURE"
                textColor="#377ac7"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart2 feature={6} ChartLabel="BLOOD PRESSURE" />
            </div>
            <div className="dash3">

              <HowToImproveButton pagelink="/myvitals/bloodpressure"/>
            </div>
          </div>
        </div> */}
        {/* <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
          <div>
            <Tooltip
              text="Blood glucose, or blood sugar, is the primary sugar found in your blood. It is your body's primary source of energy. It comes from the food you eat. Your body breaks down most of that food into glucose and releases it into your bloodstream. Symptoms of low blood sugar include sweating, shakiness, extreme hunger, nausea, or dizziness. Symptoms of high blood sugar include increased thirst, urination, fatigue, or lightheadedness. This is wellness score, not actual reading."
            />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/myvitals/bloodsugar"} className="">
              <HealthnessCard
                feature={7}
                reconID="BS"
                icon={BSIcon}
                vital="BLOOD SUGAR"
                textColor="#4f9aca"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart2 feature={7} ChartLabel="BLOOD SUGAR" />
            </div>
            <div className="dash3">
              <HowToImproveButton pagelink="/myvitals/bloodsugar"/>
            </div>
          </div>
        </div> */}
        {/* <div className="border-b border-blue-900 my-4"></div> */}
        <BackToMenu pagelink="/myhealth" menuPage="Back to My Health"/>
        <BackToPreviousPage pagelink="/dashboard" menuPage="Back to My Total Report"/>
      </div>
    </div>
  );
};

export default MyVitalDash;

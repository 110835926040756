import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import OmegaIco from '../../../assets/Healthiness/mynutrients/ICON_OMEGA3.png'

const NutriOm3 = () => {
    return (
      <>
      <ScorePage
        toolTipText='Omega-3 fatty acids are crucial for maintaining good brain health. Low levels of omega-3s have been linked to cognitive decline, memory loss, and depression. Additionally, omega-3s have been found to lower triglyceride levels, reduce blood pressure, and prevent blood clots, which can help reduce the risk of developing cardiovascular disease. For those who suffer from joint pain caused by conditions such as arthritis, omega-3s can help reduce inflammation and ease discomfort. Omega-3s are also essential for maintaining healthy skin and hair, and a lack of them can lead to dryness and other skin problems. Finally, omega-3s play a crucial role in immune function, and low levels can cause an increased risk of infections and inflammatory conditions.'
        feature={42}
        reconID="OMEGA3"
        icon={OmegaIco}
        vital="OMEGA 3"
        textColor="#5f89d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
    );
}
 
export default NutriOm3;
import React from "react";
import LeftSection from "../../common/LeftSection";
import bg from "../../../assets/Healthiness/Summary.jpg";
import FAQ1 from "../../../assets/images/faq/faq1.png";
import FAQ2 from "../../../assets/images/faq/faq2.png";
import FAQ3 from "../../../assets/images/faq/faq3.png";
import FAQ4 from "../../../assets/images/faq/faq4.png";
import FAQ5 from "../../../assets/images/faq/faq5.png";
import FAQ6 from "../../../assets/images/faq/faq6.png";
import FAQ7 from "../../../assets/images/faq/faq7.png";

const faqs = [
  {
    summary: "What are the technological principles?",
    content: `We use OCT(Optical Coherence Tomography) technology and iPPG (imaging Photo-plethysmo-graphy) as the technological principle, and generate results using iBCG (imaging Ballisto-Cardio-Graphy). 
              The iPPG technology can detect the subtle changes of circulation on the face by the camera. The OCT algorithm gets high-resolution imaging from the featured area (forehead and the regions below the eyes). 
              The iBCG gets the pattern for the interpretation of the result.`,
  },
  {
    summary: "What are the technological principles?",
    content: `
    We use a regular optical camera (such as a common web cam or
                  USB camera) to scan the key facial areas (forehead and areas
                  below the eyes). Then, our AI algorithm generates results.
                  Based on the basic vital signs data, the algorithm combines
                  and infers other data to predict additional vital signs. The
                  basic vital signs we support are:
    `,
    innerlist: [
      "HR (heart rate)",
      "BR (breathing rate)",
      "SPO2 (blood oxygen)",
      "CHOL (cholesterol)",
      "BP (blood pressure)",
      "BS (blood sugar)",
    ],
  },
  {
    summary: "How to ensure the scanning quality?",
    content: `Although the scanning can be done by normal camera like
                  built-in camera on laptop or webcam, it’s better to follow
                  below guidelines to make sure the scanning is in good quality.`,
    innerlist: [
      `There is a supplementary light source in front of the
                      face, such as a desk lamp, or facing a window during the
                      day to ensure that there are no shadows on the face.`,

      `Avoid direct sunlight which will cause overexposure.`,
      `Avoid having a too bright light source above or behind the
                      head, as it may cause shadows on the face or under the
                      eyes.`,
      `During scanning, face the camera directly and look at the
                      lens, keeping the head still.`,
    ],
  },
  {
    summary: "Is there any detection error for different races?",
    content: `No, because it’s the same person's facial feature area that is
                  used for AI interpretation with facial differences in
                  different time sequences, and skin color does not affect it.
                  Tests have been conducted on Americans, Latinos, Asians,
                  Indians, and African races. However, shadows on the face
                  should be avoided because it is a different issue compared to
                  skin color.`,
  },
  {
    summary: "Can I use this data for medical purposes?",
    content: `No, until this technology officially passes FDA Class II and
                  medical regulatory requirements, the data from these scans
                  cannot be used for medical purposes. We will strive to meet
                  the relevant regulations as soon as possible, but before that,
                  this data can only be used as a reference for personal
                  physical status and health promotion. This information can
                  help you understand the trend of your physical condition and
                  provide recommendations for daily life, exercise, fitness, and
                  nutritional supplements.`,
  },
  {
    summary: "Will having makeup on the face affect the result?",
    content: `Even if there is normal lotion or makeup on the face, as long
                  as the subtle changes in the skin can be seen, it will not
                  affect the result, but it is still best to have a bare face.`,
  },
  {
    summary: "How to use it after logging in?",
    content: `At the bottom left corner of the page, there is a camera icon
                  with the word "scan". Clicking on it will take you to a page
                  with a video tutorial. Then click "scan" and wait for about 20
                  seconds. The video in the center of the screen will show "skip
                  video" when it's ready. Click "skip video" and then the page
                  will ask for webcam permission at the top left corner. (We
                  recommend watching the tutorial video before clicking "skip
                  video".) Click "allow" for webcam permission to start
                  scanning.`,
  },
  {
    summary: "How long does scanning take?",
    content: `During scanning, a green bar will appear at the top and will
                  reach 100% when scanning is complete. Depending on the device
                  performance, most devices can complete scanning within 8
                  seconds.`,
  },
  {
    summary: "Which browsers are currently supported?",
    content: `We currently support:`,
    innerlist: [
      `Google Chrome version 101.0.4951 or above`,
      `Microsoft Edge version 100.0.1185.29 or above`,
      `Firefox version 100 or above`,
    ],
  },
  {
    summary: `I don't see "skip video" button showing up after waiting for
                  20 seconds`,
    content: ` Please check: Whether the camera is connected to the computer
                  and working properly. You can use the following website to
                  check if the camera is working properly:
                  https://webcam-test.com/ If the camera is working properly,
                  please refresh the webpage or use incognito mode for scanning.`,
  },
  {
    summary: `Can I wear glasses or a mask during the scan?`,
    content: ` We recommend that you temporarily remove your glasses
                  (including contact lenses), masks, and other items during the
                  scan to reduce the impact of obstructions on accuracy. Please
                  align your eyes with the camera lens and keep your face within
                  the green frame during the scan.`,
  },
  {
    summary: `I encountered the error message "Failed to acquire camera
                  feed: NotReadableError.Cloud not start video source"`,
    content: ` Confirm that the camera is working properly, it is possible
                  that you are using older computer hardware, we recommend that
                  you can use newer computer hardware`,
  },
  {
    summary: `What's the frame on my face in the camera preview?`,
    content: ` This is a frame that shows whether facial features can be
                  clearly recognized. A green frame indicates better quality of
                  facial characteristics. After the camera preview image shows
                  up, wait for a red or green frame to appear around the user's
                  face. Adjust the face angle, distance, and position to keep
                  the green frame showing up. (Green frame means better quality
                  of facial characteristics) Tap the START button and keep the
                  green frame showing as much as possible during the process.
                `,
  },
  {
    summary: `Can multiple people share or can a family account be provided?`,
    content: ` No, it is not currently available. Currently, trend analysis
                  and recommendations are only provided for individual users'
                  scan records.`,
  },
  {
    summary: `Why does my scanner show satisfactorily when my score is 68?`,
    content: `All indicators show you a number from 1 to 100 with three levels: <49 (low), 50-74 (average), 75-100 (high).If your score shows 68, then your score is average, which corresponds to satisfactory on your indicator.`,
  },
  {
    summary: `What happens if I perform multiple scans in a day?`,
    content: `In the Total Report, Category, and Detail pages, the scores from your most recent scan will be displayed. Your records throughout the day will be averaged and represented as a single dot on the graph.`,
  },
];
export default function FaqComponent() {
  return (
    <div
      className="flex flex-col md:flex-row items-centert justify-centerr min-h-screen w-full min-w-full " style={{backgroundColor:"#143f6b"}}
      // style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      <div className="md:w-3/4 mx-auto">
        <div className=" min-w-full mb-5">
          <div className="container flex flex-col justify-center px-4 py-0 mx-auto md:p-0 min-w-full">
            <h2 className="text-2xl font-semibold text-center text-white py-2">
              FAQ
            </h2>
            {/* <p className="mt-0 mb-8 text-gray-600">
            React Interview Questions And Answers
          </p> */}
            <div className="space-y-2">
              {faqs.map((faq, index) => (
                <details className="w-full  shadow bg-white" key={index}>
                  <summary className="px-4 py-2">{faq.summary}</summary>
                  <div className="px-4 py-6 pt-0 ml-4   text-gray-600">
                    {faq.content}
                    {"innerlist" in faq ? (
                      <div className="ml-7 mt-2">
                        <ul className="list-disc">
                          {faq.innerlist.map((lst, index) => (
                            <li kay={index}>{lst}</li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </details>
              ))}

              <details className="w-full  shadow bg-white mt-3">
                <summary className="px-4 py-5">
                  How should I position my face in the camera preview?
                </summary>
                <div className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600 ">
                  <div className="flex md:flex-row flex-col justify-center space-y-2 md:space-y-0 space-x-1">
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      <div className=" flex flex-row md:flex-col">
                        <div className="flex mx-auto">
                          <img src={FAQ7} />
                        </div>
                        <div className="my-auto shadow border-black  rounded-lg">
                          The face is in the camera <br /> preview at the right
                          angle.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      <div className=" flex flex-row md:flex-col">
                        <div className="flex mx-auto w-full">
                          <img src={FAQ1} />
                        </div>
                        <div className="my-auto w-full max-auto">
                          Covered by mask and glasses
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600 ">
                  <div className="flex md:flex-row flex-col justify-center space-y-2 md:space-y-0 space-x-1">
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      <div className=" flex flex-row md:flex-col">
                        <div>
                          <img src={FAQ2} />
                        </div>
                        <div className="my-auto shadow   rounded-lg">
                          The angle is too high.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      <div className=" flex flex-row md:flex-col">
                        <div>
                          <img src={FAQ3} />
                        </div>
                        <div className="my-auto">
                          Covered by mask and glasses
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600 ">
                  <div className="flex md:flex-row flex-col justify-center space-y-2 md:space-y-0 space-x-1">
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      <div className=" flex flex-row md:flex-col">
                        <div>
                          <img src={FAQ4} />
                        </div>
                        <div className="my-auto shadow   rounded-lg">
                          Too far
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      <div className=" flex flex-row md:flex-col">
                        <div>
                          <img src={FAQ5} />
                        </div>
                        <div className="my-auto">Too close</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600 ">
                  <div className="flex md:flex-row flex-col justify-center space-y-2 md:space-y-0 space-x-1">
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      <div className=" flex flex-row md:flex-col">
                        <div>
                          <img src={FAQ6} />
                        </div>
                        <div className="my-auto shadow   rounded-lg">
                          Not looking at the camera
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:w-1/2">
                      {/* <div className=" flex flex-row md:flex-col">
                        <div>
                          <img src={FAQ5} />
                        </div>
                        <div className="my-auto">Too close</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </details>
            </div>
          </div>
        </div>
      </div>
      {/* <div className=" flex mx-auto w-1/4 justify-center items-centerb">
        <LeftSection />
      </div> */}
      {/* import { Disclosure } from '@headlessui/react' */}
    </div>
  );
}
